import { Injectable } from '@angular/core';
import { TipService } from '@interacta-shared/feature-tip';
import { AppSelectors, AppState } from '@modules/core/store';
import { DashboardService } from '@modules/dashboard/services/dashboard-page.service';
import { CommentLocation } from '@modules/post/models/comment.model';
import { EventPostService } from '@modules/post/services/event-post.service';
import { PostViewService } from '@modules/post/services/post-view.service';
import { PostService } from '@modules/post/services/post.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
    catchError,
    concatMap,
    delay,
    exhaustMap,
    groupBy,
    map,
    mergeMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { PostActions, PostActionsApi } from '.';
import { CloudTranslationService } from '../services/cloud-translation.service';
import * as fromActionsApi from './post/post-api.actions';
import * as fromActions from './post/post.actions';

@Injectable()
export class CommentsEffects {
    likeToggle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.likeToggleComment),
            groupBy((action) => action.comment.id),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap(({ postId, comment }) =>
                        this.postService
                            .setLikeComment(comment.id, !comment.likedByMe)
                            .pipe(
                                map((_) =>
                                    fromActionsApi.likeToggleCommentSuccess({
                                        postId,
                                        comment,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.likeToggleCommentError({
                                            postId,
                                            comment,
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.deleteComment),
            groupBy((action) => action.comment.id),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId, comment, commentIdx }) =>
                        this.postService.deleteComment(comment.id).pipe(
                            map((_) =>
                                fromActionsApi.deleteCommentSuccess({
                                    postId,
                                    comment,
                                }),
                            ),
                            catchError((error) => {
                                if (error.status === 404) {
                                    return of(
                                        fromActionsApi.deleteCommentSuccess({
                                            postId,
                                            comment,
                                        }),
                                    );
                                }
                                return of(
                                    fromActionsApi.deleteCommentError({
                                        postId,
                                        comment,
                                        commentIdx,
                                        error,
                                    }),
                                );
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    translate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.translateComment),
            groupBy((action) => action.comment.id),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId, comment, language }) =>
                        this.cloudTranslationService
                            .getCommentTranslation(comment, language, postId)
                            .pipe(
                                map((translatedComment) =>
                                    fromActionsApi.translateCommentSuccess({
                                        postId,
                                        comment,
                                        translatedComment,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.translateCommentError({
                                            postId,
                                            comment,
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    untranslate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.untranslateComment),
            map(({ comment, postId }) => {
                const untranslatedComment =
                    this.cloudTranslationService.getOriginalComment(comment.id);

                return untranslatedComment
                    ? PostActionsApi.untranslateCommentSuccess({
                          postId,
                          comment,
                          untranslatedComment,
                      })
                    : PostActionsApi.untranslateCommentError({
                          postId,
                          comment,
                          error: new Error('Original comment not found'),
                      });
            }),
        ),
    );

    add$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.addComment),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    withLatestFrom(
                        this.store.select(AppSelectors.selectRouteState),
                    ),
                    concatMap(
                        ([
                            {
                                postId,
                                delta: comment,
                                parentCommentId,
                                addAttachments,
                            },
                            route,
                        ]) =>
                            this.postService
                                .createNewComment(postId, {
                                    comment,
                                    addAttachments,
                                    parentCommentId,
                                })
                                .pipe(
                                    map(
                                        ({
                                            createdComment,
                                            postFollowedByMeEnabled,
                                        }) =>
                                            fromActionsApi.addCommentSuccess({
                                                postId,
                                                createdComment,
                                                location:
                                                    route?.appBaseRoute ===
                                                    'post-detail'
                                                        ? CommentLocation.DETAIL
                                                        : route?.appBaseRoute ===
                                                            'digital-workplace'
                                                          ? CommentLocation.POST_PREVIEW
                                                          : CommentLocation.DASHBOARD,
                                                followedByMe:
                                                    postFollowedByMeEnabled,
                                            }),
                                    ),
                                    catchError((error) =>
                                        of(
                                            fromActionsApi.addCommentError({
                                                postId,
                                                error,
                                            }),
                                        ),
                                    ),
                                ),
                    ),
                ),
            ),
        ),
    );

    addForStreaming$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.addStreamingComment),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap(
                        ({
                            postId,
                            delta: comment,
                            parentCommentId,
                            addAttachments,
                            streamingTimestamp,
                        }) =>
                            this.eventPostService
                                .createStreamingComment(postId, {
                                    comment,
                                    addAttachments,
                                    parentCommentId,
                                    streamingTimestamp,
                                })
                                .pipe(
                                    map(
                                        ({
                                            createdComment,
                                            postFollowedByMeEnabled,
                                        }) =>
                                            fromActionsApi.addStreamingCommentSuccess(
                                                {
                                                    postId,
                                                    createdComment,
                                                    followedByMe:
                                                        postFollowedByMeEnabled,
                                                },
                                            ),
                                    ),
                                    catchError((error) =>
                                        of(
                                            fromActionsApi.addCommentError({
                                                postId,
                                                error,
                                            }),
                                        ),
                                    ),
                                ),
                    ),
                ),
            ),
        ),
    );

    saveEdit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.saveEditComment),
            groupBy((action) => action.commentId),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap(
                        ({
                            postId,
                            commentId,
                            delta,
                            parentCommentId,
                            addAttachments,
                        }) =>
                            this.postService
                                .editComment({
                                    id: commentId,
                                    comment: delta,
                                    addAttachments,
                                    parentCommentId,
                                })
                                .pipe(
                                    map((updatedComment) =>
                                        fromActionsApi.saveEditCommentSuccess({
                                            postId,
                                            updatedComment,
                                        }),
                                    ),
                                    catchError((error) =>
                                        of(
                                            fromActionsApi.saveEditCommentError(
                                                {
                                                    postId,
                                                    error,
                                                },
                                            ),
                                        ),
                                    ),
                                ),
                    ),
                ),
            ),
        ),
    );

    focusComment$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PostActions.openDraftComment),
                delay(100),
                tap(({ postId }) => {
                    this.dashboardService.focusComment(postId);
                    this.postViewService.triggerShadow(postId);
                }),
            ),
        { dispatch: false },
    );

    addCommentError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActionsApi.addCommentError),
                tap(({ error }) => {
                    if (
                        error.status === 403 &&
                        error.error?.errorCode === 'LIVE_STREAMING_ONGOING'
                    ) {
                        this.tipService.warn(
                            'NOTIFICATION_MESSAGE.LABEL_CANT_COMMENT',
                        );
                    } else if (error.status === 404) {
                        this.tipService.warn(
                            'NOTIFICATION_MESSAGE.LABEL_CANT_REPLY_COMMENT_DELETED',
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    deletedCommentError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    fromActionsApi.likeToggleCommentError,
                    fromActionsApi.translateCommentError,
                    fromActionsApi.saveEditCommentError,
                ),
                tap(({ error }) => {
                    if (error.status === 404) {
                        this.tipService.warn(
                            'NOTIFICATION_MESSAGE.LABEL_CANT_HANDLE_COMMENT_DELETED',
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private postService: PostService,
        private eventPostService: EventPostService,
        private cloudTranslationService: CloudTranslationService,
        private store: Store<AppState>,
        private dashboardService: DashboardService,
        private postViewService: PostViewService,
        private tipService: TipService,
    ) {}
}
